<template lang="pug">
v-container(fluid )
  v-row
    v-img(  :src='nosotros.imagen' height='76vh' ).d-none.d-md-flex
      v-container.fill-height
        v-row.justify-center.align-center
          v-col(cols=12 md=3  style='background-color: rgba(0, 0, 0, .3); margin-bottom: 1em').justify-center.align-center
            h2.font-weight-bold.pb-2.text-uppercase.white--text(v-html='nosotros.titulo').text-center
    
    
    v-img(  :src='nosotros.imagen' height='300px' width='100%').d-md-none
      v-container.d-md-none
        v-row(  justify='center')
          v-col( align='center' cols=6 style='background-color: rgba(0, 0, 0, .3);margin-top: 5em ').d-md-none
            h2.font-weight-bold.pb-2.text-uppercase.white--text(v-html='nosotros.titulo').justify-center
    
  //- DESCRIPCION NOSOTROS
  v-row.pb-5.doradociard.justify-center.align-center
    v-col(cols=10 md=8 style="margin-bottom: 5em; margin-top: 5em").d-none.d-md-block
      v-img(  :src='nosotros.ventana' height='100px' contain )
      p.text-justify.font-weight-light.mb-3.mt-5( v-html='nosotros.descripcion')
  
    v-col(cols=11 md=7 style="margin-bottom: 1em; margin-top: 1em").d-md-none
      v-img(  :src='nosotros.ventana' height='60px' contain )
      h5.text-justify.text-center.font-weight-light.mb-3.mt-5( v-html='nosotros.descripcion' )

  //- CONTENIDO
  v-row.justify-center(style="margin-bottom: 3em; margin-top: 5em")
    //- PC
    v-col(cols=10 md=5 justify='center' align='center').d-none.d-md-block
      v-img(  :src='nosotros.ventana' height='100px' contain )
      v-col(cols=10 md=8 justify='center' align='center')
        h3.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_consejo_superior')
        p.text-center.font-weight-light.mt-5.text-justify( v-html='nosotros.texto_consejo_superior')
      v-col(cols=12)
        CarruselDeImagenes(:arreglo='nosotros.listaDeConsejoSuperiorDeArbitraje')
    //- movil
    v-col(cols=11 md=5 justify='center' align='center').d-md-none
      v-img(  :src='nosotros.ventana' height='60px' contain )
      h4.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_consejo_superior')
      p.text-caption.text-justify.font-weight-light.mt-5( v-html='nosotros.texto_consejo_superior')
      CarruselDeImagenes(:arreglo='nosotros.listaDeConsejoSuperiorDeArbitraje')

    //- linea separadora
    div.d-flex.flex-column.align-center
      .lineamedia( style='width:2px').d-none.d-md-block
    
    //- PC
    v-col(cols=10 md=5 justify='center' align='center').d-none.d-md-block
      v-img(  :src='nosotros.ventana' height='100px' contain )
      v-col(cols=10 md=8 justify='center' align='center')
        h3.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_consejo_etica').justify-center
        p.text-center.font-weight-light.mt-11.text-justify( v-html='nosotros.texto_consejo_etica')
      v-col(cols=12)
          CarruselDeImagenes(:arreglo='nosotros.listaDeConsejoEtica')
    //- movil
    v-col(cols=11 md=5 justify='center' align='center').d-md-none
      v-img(  :src='nosotros.ventana' height='60px' contain )
      h4.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_consejo_etica')
      p.text-caption.font-weight-light.mt-5.text-justify( v-html='nosotros.texto_consejo_etica')
      
      CarruselDeImagenes(:arreglo='nosotros.listaDeConsejoEtica')
  //- contenedor secretaria
  v-row.justify-center(style="margin-bottom: 3em; margin-top: 5em").d-none.d-md-flex
    //- PC
 
    v-col(cols=12 md=5 justify='center' align='center').d-none.d-md-block
      v-img(  :src='nosotros.ventana' height='100px' contain )
      v-col(cols=12 md=8 justify='center' align='center')
        h3.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_directora')
        p.text-center.font-weight-light.mt-5.text-justify( v-html='nosotros.directora')
      v-col(cols=12).text-center
          CarruselDeImagenes(:arreglo='nosotros.directora_mediacion')
    v-col(cols=12 md=5 justify='center' align='center').d-none.d-md-block
      v-img(  :src='nosotros.ventana' height='100px' contain )
      v-col(cols=12 md=8 justify='center' align='center')
        h3.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_secretaria')
        p.text-center.font-weight-light.mt-5.text-justify( v-html='nosotros.secretaria')
      v-col(cols=12)
          CarruselDeImagenes(:arreglo='nosotros.listaDeSecretariaGeneral')
   

    //- movil
  v-row.justify-center.d-md-none(style="margin-bottom: 3em").d-md-none
    v-col(cols=11 md=5 justify='center' align='center').d-md-none
      v-img(  :src='nosotros.ventana' height='60px' contain )
      h4.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_directora')
      p.text-caption.text-center.font-weight-light.mt-5.text-justify( v-html='nosotros.directora')
      CarruselDeImagenes(:arreglo='nosotros.directora_mediacion')
      


  v-row.justify-center.d-md-none(style="margin-bottom: 3em").d-md-none
    v-col(cols=11 md=5 justify='center' align='center').d-md-none
      v-img(  :src='nosotros.ventana' height='60px' contain )
      h4.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_secretaria')
      p.text-caption.text-center.font-weight-light.mt-5.text-justify( v-html='nosotros.secretaria')
      CarruselDeImagenes(:arreglo='nosotros.listaDeSecretariaGeneral')
      
  //- DESCRIPCION CONVENIO
  v-row.pb-5.doradociard.justify-center.align-center
    //- pc
    v-col(cols=10 md=7 style="margin-bottom: 5em; margin-top: 5em").d-none.d-md-block
      v-img(  :src='nosotros.ventana' height='100px' contain )
      h3.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_convenios').text-center
        
      p.text-center.font-weight-light.mt-11.text-justify( v-html='nosotros.descripcion_convenio')
      v-img(  :src='convenio.imagen' height='100px' contain ).mt-12

    //- movil
    v-col(cols=11 md=5 justify='center' align='center').d-md-none.mt-5
      v-img(  :src='nosotros.ventana' height='60px' contain )
      h4.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='nosotros.titulo_convenios')
      p.text-caption.text-center.font-weight-light.mt-5.text-justify( v-html='nosotros.descripcion_convenio')
      v-img(  :src='convenio.imagen' height='100px' contain ).mt-12





</template>

<script>
export default {
  components: {
    CarruselDeImagenes: ()=>import('./carruselDeImagenes.vue')
  }
}

</script>
    
    